import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { oneTrustUserConsent } from 'wsc/utils/oneTrustLib'
import { incrementFpv, incrementPv } from 'wsc/utils/fpv'
import { clearAllActivePosts } from 'wildsky-components'
import { setProp30 } from 'wsc/utils/googleTagManager'

// Flag for checking first page is loaded
let isFirstPage = true

// The history argument will be added automatically by React Router.
const HistoryChangeHandler = props => {
  const { history, location } = props
  incrementFpv()
  incrementPv()
  setProp30({ path: location.pathname })
  useEffect(() => {
    if (isFirstPage) {
      isFirstPage = false

      // Things that need to trigger when consent state is changed
      oneTrustUserConsent.registerListener(() => {
        // do something that need user consent here
      })

      window.embedly && window.embedly('card', 'embedly-card')
    }

    const unlisten = history.listen(() => {
      clearAllActivePosts()
    })

    return () => {
      // Unload the listener to avoid multiple calls from another page
      unlisten()
    }
  }, [history])

  return null
}

HistoryChangeHandler.propTypes = {
  history: PropTypes.object.isRequired,
}

export default withRouter(HistoryChangeHandler)
