import { createGlobalStyle } from 'styled-components/macro'
import defaultGlobalStyle from 'wsc/defaultGlobalStyle'
import { COLORS, FONT_FAMILIES, MEDIA } from '../../utils/styles'
import { FONT_FACE_AVENIR_NEXT_CONDENSED, FONT_FACE_FUTURA_PT } from '../../configs/fonts'

const GlobalStyle = createGlobalStyle`
  ${defaultGlobalStyle}  
  ${FONT_FACE_AVENIR_NEXT_CONDENSED}
  ${FONT_FACE_FUTURA_PT}
  * {
    box-sizing: border-box;
    word-break: break-word;
    margin: 0;
    padding: 0;
  }

  html {
    -webkit-text-size-adjust: 100%; /* Prevent font scaling in landscape while allowing user zoom */
  }

  body {
    /** Ad callouts */
    --adCalloutFontFamily: 'OpenSans', sans-serif;
    --adCalloutFontSize: 12px;

    /** Social Button */
    --socialButton_CircleColor: ${COLORS.CM_BLUE};
    --socialButton_CircleHoverColor: ${COLORS.CM_YELLOW};
    --socialButton_CircleActiveColor: ${COLORS.CM_DARK_YELLOW};
    --socialButton_NoBgColor: ${COLORS.CM_WHITE};
    --socialButton_NoBgHoverColor: ${COLORS.CM_YELLOW};
    --socialButton_NoBgActiveColor: ${COLORS.CM_DARK_YELLOW};

    /** inputBoxDefault */
    --inputBoxDefault_borderColor: ${COLORS.CM_VERY_LIGHT_GRAY};
    --inputBoxFocus_borderColor: ${COLORS.CM_VERY_LIGHT_GREEN};
    --inputBoxValid_borderColor: ${COLORS.CM_BLUE};

    /** MUI */
    --checkbox_Color: ${COLORS.CM_GREEN};
    --selectedListItem_BgColor: ${COLORS.CM_GREEN_08};
    --chip_BgColor: ${COLORS.CM_VERY_LIGHT_BLUE};
    --touchRipple_BgColor: ${COLORS.CM_GREEN};

    /** defaultPlaceholder */
    --defaultPlaceholder_color: ${COLORS.GREY};

    /** Link */
    --defaultLinkStyle_Color: ${COLORS.BLACK};
    --defaultLinkStyle_BorderBottomColor: ${COLORS.CM_BLUE};

    /* ContentMarkdown */
    --contentMarkdown_figureCaption_color: ${COLORS.GREY};
    --contentMarkdown_figureContainer_linkBackgroundColor: ${COLORS.CM_BLUE};
    --contentMarkdown_figureContainer_linkHoverBefore_opacity: 0.6;
    --contentMarkdown_startQuoteContainer_display: none;
    --contentMarkdown_endQuoteContainer_display: none;
    --contentMarkdown_iconTwitterFillColor: ${COLORS.CM_BLUE} !important;
    --contentMarkdown_quoteShareText_borderBottom: 0.125rem solid ${COLORS.CM_BLUE};
  }

  body, .font-body, .font-body-serif {
    word-break: break-word;
    color: ${COLORS.BLACK};
    font-size: 1.13rem;
    line-height: 1.7;

    ${MEDIA.TABLET`font-size: 1.19rem;`}
    ${MEDIA.DESKTOP`font-size: 1.25rem;`}
  }

  body, .font-body {
    font-family: ${FONT_FAMILIES.SANSSERIF};
  }

  .font-body-serif {
    font-family: ${FONT_FAMILIES.SERIF};
  }

  h1, h2, h3, h4, .font-h1, .font-h2, .font-h3, .font-h4 {
    font-family: ${FONT_FAMILIES.SANSSERIF};
    color: ${COLORS.BLACK};
  }

  h1, .font-h1 {
    font-weight: bold;
    font-size: 1.63rem;
    line-height: 1.33;

    ${MEDIA.TABLET`font-size: 2.31rem;`}
    ${MEDIA.DESKTOP`font-size: 2.94rem;`}
  }

  h2, .font-h2 {
    font-weight: 600;
    font-size: 1.44rem;
    line-height: 1.33;

    ${MEDIA.TABLET`font-size: 1.88rem;`}
    ${MEDIA.DESKTOP`font-size: 2.19rem;`}
  }

  h3, .font-h3 {
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.6;

    ${MEDIA.TABLET`font-size: 1.5rem;`}
    ${MEDIA.DESKTOP`font-size: 1.69rem;`}
  }

  h4, .font-h4 {
    font-weight: bold;
    font-size: 1.13rem;
    line-height: 1.7;

    ${MEDIA.TABLET`font-size: 1.19rem;`}
    ${MEDIA.DESKTOP`font-size: 1.25rem;`}
  }

  blockquote {
    font-family: ${FONT_FAMILIES.SERIF};
    font-size: 1.88rem;
    font-style: italic;
    color: ${COLORS.BLACK};
    line-height: 1.6;

    ${MEDIA.MOBILE`font-size: 1.44rem;`}
  }

  hr {
    border: none;
    height: 0.0625rem;
    background-color: ${COLORS.GRAY};
  }

  .font-small-body {
    font-size: 1rem;
    line-height: 1.7;

    ${MEDIA.TABLET`font-size: 0.95rem`}
    ${MEDIA.DESKTOP`font-size: 0.94rem`}
  }

  .font-description {
    /* font size and line-height style based on 'Description' style in style guide */
    font-family: ${FONT_FAMILIES.SANSSERIF};
    font-size: 0.75rem;
    line-height: 1.7;

    ${MEDIA.TABLET`font-size: 0.75rem;`}
    ${MEDIA.DESKTOP`font-size: 0.69rem;`}
  }

  .font-section-header-1 {
    font-family: ${FONT_FAMILIES.SERIF};
    font-weight: normal;
    font-size: 1.44rem;
    line-height: 1.33;

    ${MEDIA.TABLET`font-size: 1.88rem;`}
    ${MEDIA.DESKTOP`font-size: 2.19rem;`}
  }

  .font-section-header-2 {
    font-family: ${FONT_FAMILIES.SERIF};
    font-weight: normal;
    font-size: 1.13rem;
    line-height: 1.7;

    ${MEDIA.TABLET`font-size: 1.19rem;`}
    ${MEDIA.DESKTOP`font-size: 1.25rem;`}
  }


  /* TODO: Fix these fonts according to updated style-guide */
  .font-button-title {
    font-size: 1.8em;
  }

  .font-button-subtitle {
    font-weight: bold;
    font-size: 0.86rem;

    ${MEDIA.TABLET`font-size: 0.71rem;`}
    ${MEDIA.DESKTOP`font-size: 0.79rem;`}
  }

  .font-button {
    font-family: ${FONT_FAMILIES.SANSSERIF};
    line-height: 1.59;
    font-size: 0.94rem;
  }

  /* -------------------------------------------------------- */
  /* Below are a custom CSS className used for override a global style of all needed components
  /* -------------------------------------------------------- */

  .h4-parenting-title {
    ${MEDIA.DESKTOP`
    font-size: 1rem;
    line-height: 1.38;
    svg {
      width: 13px; 
      height: 13px;
    }`}
  }

  .suggested-title {
    ${MEDIA.DESKTOP`font-size: 0.94rem;`}
    ${MEDIA.TABLET`font-size: 0.95rem;`}
    ${MEDIA.MOBILE`font-size: 1rem;`}
    font-weight: bold;
    font-family: ${FONT_FAMILIES.SANSSERIF};
    line-height: 1.7;
  }

  /* --------------------------------------------------------- */
  /* Overrides for wsc defaults */
  /* --------------------------------------------------------- */
  
  .AuthorImage {
    --withImageBoxShadow_boxShadowColor: ${COLORS.CM_GREEN};
  }

  .WebinarButton {
    --withButton_BackgroundColor: ${COLORS.LT_HOSPITAL_GREEN};
    --withButton_HoverBackgroundColor: ${COLORS.LT_SUN_YELLOW};
    --withButton_ActiveBackgroundColor: ${COLORS.LT_DARK_SUN_YELLOW};
  }

  .ValentineButton {
    --withButton_BackgroundColor: ${COLORS.VALENTINE_DARK_PINK};
    --withButton_HoverBackgroundColor: ${COLORS.VALENTINE_PINK};
    --withButton_ActiveBackgroundColor: ${COLORS.VALENTINE_PURPLE};
    --withButton_Color: ${COLORS.WHITE};
    --withButton_HoverColor: ${COLORS.WHITE};
    --withButton_ActiveColor: ${COLORS.WHITE};
  }

  .MothersDayButton {
    --withButton_BackgroundColor: ${COLORS.MOTHERS_DAY_GREEN};
    --withButton_HoverBackgroundColor: ${COLORS.MOTHERS_DAY_DARK_PINK};
    --withButton_ActiveBackgroundColor: ${COLORS.MOTHERS_DAY_PINK};
  }

  .FathersDayButton {
    --withButton_BackgroundColor: ${COLORS.FATHERS_DAY_BLUE};
    --withButton_HoverBackgroundColor: ${COLORS.FATHERS_DAY_YELLOW};
    --withButton_ActiveBackgroundColor: ${COLORS.FATHERS_DAY_ORANGE};
  }

  .FirstDayOfSchoolButton {
    --withButton_BackgroundColor: ${COLORS.SCHOOL_DAY_PINK};
    --withButton_HoverBackgroundColor: ${COLORS.SCHOOL_DAY_ORANGE};
    --withButton_ActiveBackgroundColor: ${COLORS.SCHOOL_DAY_GREEN};
    --withButton_Color: ${COLORS.BLACK};
    --withButton_HoverColor: ${COLORS.BLACK};
    --withButton_ActiveColor: ${COLORS.BLACK};
    --withButton_Border: 1px solid ${COLORS.BLACK};
    --withButton_HoverBorder: 1px solid ${COLORS.BLACK};
    --withButton_ActiveBorder: 1px solid ${COLORS.BLACK};
  }

  .HalloweenButton {
    --withButton_BackgroundColor: ${COLORS.HALLOWEEN_GREEN};
    --withButton_HoverBackgroundColor: ${COLORS.HALLOWEEN_SOFT_GREEN};
    --withButton_ActiveBackgroundColor: ${COLORS.HALLOWEEN_PINK};
    --withButton_Color: ${COLORS.BLACK};
    --withButton_HoverColor: ${COLORS.BLACK};
    --withButton_ActiveColor: ${COLORS.BLACK};
    --withButton_Border: 1px solid ${COLORS.BLACK};
    --withButton_HoverBorder: 1px solid ${COLORS.BLACK};
    --withButton_ActiveBorder: 1px solid ${COLORS.BLACK};
  }

  .ThanksgivingButton {
    --withButton_BackgroundColor: ${COLORS.THANKSGIVING_ORANGE};
    --withButton_HoverBackgroundColor: ${COLORS.THANKSGIVING_DARK_ORANGE};
    --withButton_ActiveBackgroundColor: ${COLORS.THANKSGIVING_SOFT_ORANGE};
    --withButton_Color: ${COLORS.BLACK};
    --withButton_HoverColor: ${COLORS.BLACK};
    --withButton_ActiveColor: ${COLORS.BLACK};
  }

  .HolidayButton {
    --withButton_BackgroundColor: ${COLORS.HOLIDAY_DARK_ORANGE};
    --withButton_HoverBackgroundColor: ${COLORS.HOLIDAY_ORANGE};
    --withButton_ActiveBackgroundColor: ${COLORS.HOLIDAY_ORANGE};
    --withButton_Color: ${COLORS.WHITE};
    --withButton_HoverColor: ${COLORS.WHITE};
    --withButton_ActiveColor: ${COLORS.WHITE};
  }
  
  /** Button Action */
  .buttonAction {
    --buttonAction_Height: 50px;
    --buttonAction_FontFamily: ${FONT_FAMILIES.SANSSERIF};
  }

  .BrandedContent {
    --withImageBoxShadow_boxShadowColor: ${COLORS.CM_GREEN}
  }
`

export default GlobalStyle
