import React, { useEffect } from 'react'
import { isRampSet } from '../../utils/ramp'
import { getTargeting } from '../../utils/adUnits'
import { createGlobalStyle } from 'styled-components/macro'

const InContentAdStyle = createGlobalStyle`
  .pw-ad-scroll-container {
    margin-top: 0 !important;
    margin-bottom: var(--inContentAdMarginBottom) !important;
  }
`

const RawAdSlot = ({ unit, tagless, placeholder }) => {
  let component = !tagless ? (
    <div id={unit.selectorId} />
  ) : placeholder ? (
    <div className="pw-incontent" />
  ) : (
    <InContentAdStyle />
  )
  useEffect(() => {
    isRampSet().then(function() {
      window.ramp.custom_tags = getTargeting()
      window.ramp &&
        window.ramp.que.push(() => {
          window.ramp
            // pass in the array where you defined the units
            .addUnits([unit])
            .then(() => {
              // then show the units
              window.ramp.displayUnits()
            })
            .catch(e => {
              // catch errors
              window.ramp.displayUnits()
              console.log(e)
            })
        })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return component
}

export default RawAdSlot
